export const formatDate = (formDate)=>{
    const _date = new Date(formDate);
    const MM = String(_date.getMonth()+1).length == 1 ? "0"+(_date.getMonth()+1) : _date.getMonth()+1;
    const DD = String(_date.getDate()).length == 1 ? "0"+_date.getDate() : _date.getDate();
    const YY = _date.getFullYear() ;

    return MM+"/"+DD+"/"+YY
}

export const inputFormatDate = (formDate)=>{
    if(!formDate) return "" 
    const _date = new Date(formDate);
    const MM = String(_date.getMonth()+1).length == 1 ? "0"+(_date.getMonth()+1) : _date.getMonth()+1;
    const DD = String(_date.getDate()).length == 1 ? "0"+_date.getDate() : _date.getDate();
    const YY = _date.getFullYear() ;

    return YY+"-"+MM+"-"+DD
}


export const dateDifference = (formDate,currentDate)=>{
    
    const _formDate = new Date(formDate);   
    
    const _currentDate = new Date(currentDate);   
    
    const diff = _currentDate.getTime() - _formDate.getTime();   
    
    const dayDiff = diff / (1000 * 60 * 60 * 24); 
    // const _date = new Date(formDate);
    // const MM = String(_date.getMonth()+1).length == 1 ? "0"+_date.getMonth()+1 : _date.getMonth()+1;
    // const DD = String(_date.getDate()).length == 1 ? "0"+_date.getDate() : _date.getDate();
    // const YY = _date.getFullYear() ;

    return dayDiff
}

export const incrementDate = (formDate,increaseDays)=>{
    if(typeof increaseDays != 'number') return "parameter error"
    if(!formDate) return "" ;
    const _incrementDays = weekendDaysCount(formDate,increaseDays) + increaseDays ;
    const date = new Date(formDate);

    date.setDate(date.getDate() + (_incrementDays) )
    const _date = new Date(date);
    const MM = String(_date.getMonth()+1).length == 1 ? "0"+(_date.getMonth()+1) : _date.getMonth()+1;
    const DD = String(_date.getDate()).length == 1 ? "0"+_date.getDate() : _date.getDate();
    const YY = _date.getFullYear() ;

    return YY+"-"+MM+"-"+DD
}

export const getNexFeesValue =(formInput) =>{
    const _discount = formInput.discount  ? parseFloat(formInput.discount ) : 0;
    const _savings = formInput.savings ? parseFloat(formInput.savings): 0;
    
    const _result = _discount + (_savings*0.1) ;

    return {nex_fees:_result}
  }

export const getNetSavingsValue =(formInput) =>{

    const _discount = formInput.discount  ? parseFloat(formInput.discount ) : 0;
    const _savings = formInput.savings ? parseFloat(formInput.savings): 0;

    const _nexFees = formInput.nex_fees ? parseFloat(formInput.nex_fees): 0;
    const _otherFees = formInput.other_fees ? parseFloat(formInput.other_fees): 0;
    
    const _result = (_discount + _savings) - (_nexFees + _otherFees) ;
    
    
    // console.log("netSavingsCalculate",_result,formInput.net_savings)
    
    return {net_savings:_result}

  }

export const getPercentageSavingsValue =(formInput) =>{
    const _netSavings = formInput.net_savings ? parseFloat(formInput.net_savings): 0;
    const _totalCharge = formInput.total_charge ? parseFloat(formInput.total_charge) : 1;
    
    const _result = (_netSavings/_totalCharge)*100 ;
    
    return {percentage_savings:String((_result).toFixed(3))}

  }

export const getAllowableValue =(formInput) =>{
    const _totalCharge = formInput.total_charge ? parseFloat(formInput.total_charge) : 0;
    const _discount = formInput.discount ? parseFloat(formInput.discount) : 0;
    const _savings = formInput.savings ? parseFloat(formInput.savings): 0;
    const _allowable = _totalCharge - _discount - _savings ;
    
    return {allowable:_allowable}
  }

export const getChequeCleared = (formInput)=>{
  
    if(formInput.ck_cleared){
      const ckCleared = formInput.ck_cleared ;
      const notificationEndDate = new Date(incrementDate(ckCleared,30)).toISOString();
    //   console.log("notificationEndDate",notificationEndDate)
    return {open_negotiation_notice_end_of_right_to_initiate_on : notificationEndDate}
      
    }
    return ""
  }

const weekendDaysCount = (startDate,increaseDays)=>{
    // Set the start and end dates
    const _startDate = new Date(startDate);
    let endDate = new Date(startDate);
    endDate.setDate(endDate.getDate()+increaseDays)
    endDate = new Date(endDate) ;
    // Loop through each day between the start and end dates
    let sundayCount = 0;
    let saturdayCount = 0;
    for (let d = _startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
        // Check if the current day is a Sunday or Monday
        if (d.getDay() === 0) {
            sundayCount++;
        } else if (d.getDay() === 6) {
            saturdayCount++;
        }
    }

    console.log("weekendDaysCount",saturdayCount+saturdayCount,increaseDays)
    return saturdayCount+saturdayCount

}