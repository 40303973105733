import React ,{useState,useEffect,useCallback}from 'react';
import {Route, Link, Routes, useParams,useNavigate} from 'react-router-dom';
import NavBar from '../../shared/navbar/navbar';
import { incrementDate, getNexFeesValue, getPercentageSavingsValue, getNetSavingsValue, getChequeCleared, getAllowableValue} from '../../shared/functions/service';
import axios from 'axios';
import "./form.css";
import { inputFormatDate } from '../../shared/functions/service';
import { API_LINK } from '../../shared/functions/config';
const formObject = {
  "co_pay": "",
  "claim_number": "",
  "form_type": "",
  "patient_name": "",
  "payee": "",
  "dos": "",
  "total_charge": "",
  "discount": "",
  "savings": "",
  "allowable": "",
  "paid": "",
  "nex_fees": "",
  "other_fees": "",
  "net_savings": "",
  "percentage_savings": "",
  "ck_number": "",
  "agrmt_sent": "",
  "ck_mailed": "",
  "ck_received": "",
  "ck_cleared": "",
  "reissued": "",
  "first_appeal_received": "",
  "date_to_send_closure_ltr": "",
  "closure_sent": "",
  "date_to_send_first_appeal_response": "",
  "first_appeal_response_sent": "",
  "second_appeal_received": "",
  "open_negotiation_notice_end_of_right_to_initiate_on": "",
  "open_negotiation_notice_received_date": "",
  "open_negotiation_period_ending": "",
  "notice_of_idr_initiation_end_of_right_to_initiate": "",
  "notice_of_idr_initiation_received_date": "",
  "selection_of_certified_idr_entity": "",
  "final_selection_of_idr_entity": "",
  "submission_of_offer": "",
  "selection_of_offer_and_decision": "",
  "payment_of_oon_rate": "",
  "cooling_off_period": ""
}

const AdminForm = () => {

  const [input, setInput] = useState(formObject);
  const [isInputChange, setIsInputChange] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
    const handleSubmit = async(e) => {
        try {
            e.preventDefault();
            // console.log(input);

            const token = localStorage.getItem("key") ;
            const response = await axios.post(API_LINK+"/user-form/edit/"+params.id,input,{headers: {
              'Authorization': `Bearer ${token}` 
            }})
            // console.log("response",response)
            if(response.status == 200){
                const result = response.data;
                
                alert("Submit successful");
                return navigate("/dashboard")
            }else{
                return alert("Form Error")
            }
        } catch (error) {
          console.log("error",error)
            // const msg = handleError(error);
            if(error.response){
              return alert(error.response.data.text)
            }else{
              return alert("Server error")
            }
            
        }
    }

      const nexFeesCalculate =() =>{
        
        
        const _result = getNexFeesValue(input)
        updateFormInput(_result)
        setIsInputChange(!isInputChange)
        // return _result
      }
  
      const netSavingsCalculate =() =>{
        
        const _result = getNetSavingsValue(input) ;
        
        updateFormInput(_result);
        // console.log("netSavingsCalculate",_result,input.net_savings)
        
        // return _result
  
      }
  
      const percentageSavingsCalculate =() =>{
        const _result = getPercentageSavingsValue(input) ;
        updateFormInput(_result)
        // return _result
  
      }
  
      const handleChange = useCallback((event)=>{
        if(event){
          
          const value  = {[event.target.name]:event.target.value};
          // console.log("value",value)
          setInput((input) => ({...input,...value  }));
          
        }
        setIsInputChange(!isInputChange)
      },[input])
  
  
      const updateFormInput = (value)=>{
        setInput((input) => ({...input,...value  }));
        
      }
  
      const allowableCalculate =() =>{
        
        const _result = getAllowableValue(input)
        updateFormInput(_result)
        setIsInputChange(!isInputChange)
        
      }
  
      const chequeCleared = ()=>{
        if(input.ck_cleared){
          const _result = getChequeCleared(input);
          
          updateFormInput(_result)
        }
      }

      const rightToInitiate = ()=>{
        if(input.open_negotiation_notice_received_date){
          const _receivedDate = incrementDate(input.open_negotiation_notice_received_date,30);
          const _notificationInitiate = incrementDate(input.open_negotiation_notice_received_date,4); 
          const _result = {open_negotiation_period_ending:_receivedDate,notice_of_idr_initiation_end_of_right_to_initiate:_notificationInitiate}
          updateFormInput(_result)
        }
      }

      const initiationReceivedDate = ()=>{
        if(input.notice_of_idr_initiation_received_date){
          const _receivedDate = incrementDate(input.notice_of_idr_initiation_received_date,3);
          
          const _finalSelection = incrementDate(input.notice_of_idr_initiation_received_date,6);
          
          const _submissionOffer  = incrementDate(_finalSelection,10) ;

          const _selectionOfOfferAndDecision  = incrementDate(_finalSelection,10) ;

          const _paymentOfOonRate = incrementDate(_selectionOfOfferAndDecision,30);

          const _coolingOffPeriod = incrementDate(_selectionOfOfferAndDecision,90);

          const _result = {
            selection_of_certified_idr_entity:_receivedDate,
            submission_of_offer:_submissionOffer,
            final_selection_of_idr_entity:_finalSelection,
            selection_of_offer_and_decision:_selectionOfOfferAndDecision,
            payment_of_oon_rate: _paymentOfOonRate,
            cooling_off_period: _coolingOffPeriod
          }
          updateFormInput(_result)
        }
      }

      const load = useCallback(async()=>{
        const id = params.id ;
        const token = localStorage.getItem("key") ;
        const response = await axios.get(API_LINK+"/user-form/by/"+id,{headers: {
             'Authorization': `Bearer ${token}` 
         }})
         // console.log(response)
         if(response.status == 200){
             const result = response.data.result;
             setInput(result)
         }
      })
  
      useEffect(()=>{
        
        netSavingsCalculate();
        percentageSavingsCalculate();
        
      },[isInputChange])

      

    useEffect(() => {
      
      load();
  }, []);

  
  return (
    <>
      <NavBar/>
      <h1>Process Record</h1>
      <p>Please fill in this form to generate new Ticket.</p>
      <form  autoComplete='off' onSubmit={handleSubmit}>
          <div className="formContainer" >
              
              
              <hr />

              <h3 className="inpLbl" htmlFor="type"><b>Type</b></h3><br/>
              <input type="text" onChange={handleChange} value={input.form_type}  placeholder="Enter type" name="form_type"  id="type" required />

              <br />
              <h3 className="inpLbl" htmlFor="claimNo"><b>Claim Number</b></h3><br/>
              <input type="text" onChange={handleChange} value={input.claim_number}  placeholder="Enter claim number" name="claim_number" id="claimNo" required />

              <br />
              <h3 className="inpLbl" htmlFor="patient_name"><b>Patient Name</b></h3><br/>
              <input type="text" onChange={handleChange} value={input.patient_name}  placeholder="Enter Patient Name" name="patient_name" id="patient_name" required />
              <br />
              <h3 className="inpLbl" htmlFor="payee"><b>Payee</b></h3><br/>
              <input type="text" onChange={handleChange} value={input.payee}  placeholder="Enter payee" name="payee" id="payee"  />
              <br />
              <h3 className="inpLbl" htmlFor="dos"><b>DOS</b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} value={inputFormatDate(input.dos)}  placeholder="Enter DOS" name="dos" id="dos"  />
              <br />
              <h3 className="inpLbl" htmlFor="total_charge"><b>Total Charge</b></h3><br/>
              <span className='preInp'>$</span><input type="text" onChange={(e) => handleChange(e)} onBlur={()=>{allowableCalculate();percentageSavingsCalculate()}} value={input.total_charge}  placeholder="Enter total charge" name="total_charge" id="total_charge"  />
              <br />
              <h3 className="inpLbl" htmlFor="discount"><b>Discount</b></h3><br/>
              <span className='preInp'>$</span><input type="text" onChange={handleChange} onBlur={()=>{allowableCalculate();netSavingsCalculate();nexFeesCalculate()}} value={input.discount}  placeholder="Enter discount" name="discount" id="discount"  />
              <br />
              <h3 className="inpLbl" htmlFor="savings"><b>Savings</b></h3><br/>
              <span className='preInp'>$</span><input type="text" onChange={handleChange} onBlur={()=>{allowableCalculate();netSavingsCalculate();nexFeesCalculate()}} value={input.savings}  placeholder="Enter savings" name="savings" id="savings"  />
              <br />
              <h3 className="inpLbl" htmlFor="allowable"><b>Allowable</b></h3><br/>
              <span className='preInp'>$</span><input type="text" className='autoInput' onChange={handleChange} value={input.allowable}  placeholder="Enter Allowable" name="allowable" id="allowable"  />
              <br />
              <h3 className="inpLbl" htmlFor="co_pay"><b>Co Pay</b></h3><br/>
              <span className='preInp'>$</span><input type="text" onChange={handleChange} value={input.co_pay}  placeholder="Enter co pay" name="co_pay" id="co_pay"  />
              <br />
              <h3 className="inpLbl" htmlFor="paid"><b>Paid</b></h3><br/>
              <span className='preInp'>$</span><input type="text" onChange={handleChange} value={input.paid}  placeholder="Enter paid" name="paid" id="paid"  />
              <br />
              <h3 className="inpLbl" htmlFor="nex_fees"><b>Nex fees</b></h3><br/>
              <span className='preInp'>$</span><input type="text" className='autoInput' onChange={handleChange} onBlur={()=>{netSavingsCalculate()} } value={input.nex_fees}  placeholder="Enter Nex Fees" name="nex_fees" id="nex_fees"  />
              <br />
              <h3 className="inpLbl" htmlFor="other_fees"><b>Other Fees</b></h3><br/>
              <span className='preInp'>$</span><input type="text" onChange={handleChange} onBlur={()=>{netSavingsCalculate();percentageSavingsCalculate()} } value={input.other_fees}  placeholder="Enter other fees" name="other_fees" id="other_fees"  />
              <br />
              <h3 className="inpLbl" htmlFor="net_savings"><b>Net Savings</b></h3><br/>
              <span className='preInp'>$</span><input type="text" className='autoInput' onChange={()=>{handleChange();}} value={input.net_savings}  placeholder="Enter net savings" name="net_savings" id="net_savings"  />
              <br />
              <h3 className="inpLbl" htmlFor="percentage_savings"><b>Percentage savings</b></h3><br/>
              <span className='preInp'>%</span><input type="text" className='autoInput' onChange={handleChange} value={input.percentage_savings}  placeholder="Enter percentage savings" name="percentage_savings" id="percentage_savings"  />
              <br />
              <h3 className="inpLbl" htmlFor="ck_number"><b>CK Number</b></h3><br/>
              <input type="text" onChange={handleChange} value={input.ck_number}  placeholder="Enter ck number" name="ck_number" id="ck_number"  />


              <br />
              <h3 className="inpLbl" htmlFor="agrmt_sent"><b>Agrmt Sent</b></h3><br/>
              <input type="text" onChange={handleChange} value={input.agrmt_sent}  placeholder="Enter Agrmt Sent" name="agrmt_sent" id="agrmt_sent"  />
              <br />
              <h3 className="inpLbl" htmlFor="ck_mailed"><b>CK Mailed</b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} value={inputFormatDate(input.ck_mailed)}  placeholder="Enter ck mailed" name="ck_mailed" id="ck_mailed"  />
              <br />
              <h3 className="inpLbl" htmlFor="ck_received"><b>CK Received</b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} value={inputFormatDate(input.ck_received)}  placeholder="Enter ck received" name="ck_received" id="ck_received"  />
              <br />
              <h3 className="inpLbl" htmlFor="ck_cleared"><b>CK Cleared</b></h3><br/>
              <input type="date" className='dateInp' onChange={(e)=>{handleChange(e)}} onBlur={()=>{chequeCleared();}} value={inputFormatDate(input.ck_cleared)}  placeholder="Enter ck cleared" name="ck_cleared" id="ck_cleared"  />
              <br />
              <h3 className="inpLbl" htmlFor="reissued"><b>Reissued</b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} value={inputFormatDate(input.reissued)}  placeholder="Enter reissued" name="reissued" id="reissued"  />
              <br />
              <hr />
              <h1>Appeal Tracker</h1>
              <h3 className="inpLbl" htmlFor="first_appeal_received"><b>First appeal received</b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} value={inputFormatDate(input.first_appeal_received)}  placeholder="Enter first appeal received" name="first_appeal_received" id="first_appeal_received"  />
              <br />
              <h3 className="inpLbl" htmlFor="date_to_send_closure_ltr"><b> Date to send closure ltr</b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} value={inputFormatDate(input.date_to_send_closure_ltr)}  placeholder="Enter date to send closure ltr" name="date_to_send_closure_ltr" id="date_to_send_closure_ltr"  />
              <br />
              <h3 className="inpLbl" htmlFor="closure_sent"><b>Closure Sent</b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} value={inputFormatDate(input.closure_sent)}  placeholder="Enter closure sent" name="closure_sent" id="closure_sent"  />
              <br />
              <h3 className="inpLbl" htmlFor="date_to_send_first_appeal_response"><b> Date to send first appeal response</b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} value={inputFormatDate(input.date_to_send_first_appeal_response)}  placeholder="Enter date to send first appeal response" name="date_to_send_first_appeal_response" id="date_to_send_first_appeal_response"  />
              <br />
              <h3 className="inpLbl" htmlFor="first_appeal_response_sent"><b>First appeal response Sent</b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} value={inputFormatDate(input.first_appeal_response_sent)}  placeholder="Enter first appeal response Sent" name="first_appeal_response_sent" id="first_appeal_response_sent"  />
              <br />
              <h3 className="inpLbl" htmlFor="second_appeal_received"><b>CK Second appeal received</b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} value={inputFormatDate(input.second_appeal_received)}  placeholder="Enter Second appeal received" name="second_appeal_received" id="second_appeal_received"  />
              <br />
              <hr />
              <h1>IDR Tracker</h1>
              <h3 className="inpLbl" htmlFor="open_negotiation_notice_end_of_right_to_initiate_on"><b>Open negotiation notice end of right to initiate on</b></h3><br/>
              <input type="date" className='dateInp autoInput' onChange={handleChange} value={inputFormatDate(input.open_negotiation_notice_end_of_right_to_initiate_on)}   placeholder="Enter open negotiation notice end of right to initiate on" name="open_negotiation_notice_end_of_right_to_initiate_on" id="open_negotiation_notice_end_of_right_to_initiate_on"  />
              <br />
              <h3 className="inpLbl" htmlFor="open_negotiation_notice_received_date"><b>Open negotiation notice received date </b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} value={inputFormatDate(input.open_negotiation_notice_received_date)} onBlur={()=> rightToInitiate() } placeholder="Enter open negotiation notice received date" name="open_negotiation_notice_received_date" id="open_negotiation_notice_received_date"  />
              <br />
              <h3 className="inpLbl" htmlFor="open_negotiation_period_ending"><b>Open negotiation period ending</b></h3><br/>
              <input type="date" className='dateInp autoInput' onChange={handleChange} value={inputFormatDate(input.open_negotiation_period_ending)}  placeholder="Enter Open negotiation period ending" name="open_negotiation_period_ending" id="open_negotiation_period_ending"  />
              <br />
              <h3 className="inpLbl" htmlFor="notice_of_idr_initiation_end_of_right_to_initiate"><b>Notice of IDR initiation end of right to initiate</b></h3><br/>
              <input type="date" className='dateInp autoInput' onChange={handleChange} value={inputFormatDate(input.notice_of_idr_initiation_end_of_right_to_initiate)}  placeholder="Enter Open negotiation period ending" name="notice_of_idr_initiation_end_of_right_to_initiate" id="notice_of_idr_initiation_end_of_right_to_initiate"  />
              <br />
              <h3 className="inpLbl" htmlFor="notice_of_idr_initiation_received_date"><b>Notice of idr initiation received date</b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} value={inputFormatDate(input.notice_of_idr_initiation_received_date)} onBlur={()=> initiationReceivedDate() }  placeholder="Enter Notice of idr initiation received date" name="notice_of_idr_initiation_received_date" id="notice_of_idr_initiation_received_date"  />
              <br />
              <h3 className="inpLbl" htmlFor="selection_of_certified_idr_entity"><b>Selection of certified idr entity</b></h3><br/>
              <input type="date" className='dateInp autoInput' onChange={handleChange} value={inputFormatDate(input.selection_of_certified_idr_entity)}  placeholder="Enter Selection of certified idr entity" name="selection_of_certified_idr_entity" id="selection_of_certified_idr_entity"  />
              <br />
              <h3 className="inpLbl" htmlFor="final_selection_of_idr_entity"><b>Final selection of idr entity</b></h3><br/>
              <input type="date" className='dateInp autoInput' onChange={handleChange} value={inputFormatDate(input.selection_of_certified_idr_entity)}  placeholder="Enter Final selection of idr entity" name="final_selection_of_idr_entity" id="final_selection_of_idr_entity"  />
              <br />
              <h3 className="inpLbl" htmlFor="submission_of_offer"><b>Submission of offer</b></h3><br/>
              <input type="date" className='dateInp autoInput' onChange={handleChange} value={inputFormatDate(input.submission_of_offer)}  placeholder="Enter Submission of offer" name="submission_of_offer" id="submission_of_offer"  />
              <br />
              <h3 className="inpLbl" htmlFor="selection_of_offer_and_decision"><b>Selection of offer and decision</b></h3><br/>
              <input type="date" className='dateInp autoInput' onChange={handleChange} value={inputFormatDate(input.selection_of_offer_and_decision)}  placeholder="Enter Selection of offer and decision" name="selection_of_offer_and_decision" id="selection_of_offer_and_decision"  />
              <br />
              <h3 className="inpLbl" htmlFor="payment_of_oon_rate"><b>Payment of oon rate</b></h3><br/>
              <input type="date" className='dateInp autoInput' onChange={handleChange} value={inputFormatDate(input.payment_of_oon_rate)}  placeholder="Enter Payment of oon rate" name="payment_of_oon_rate" id="payment_of_oon_rate"  />
              <br />
              <h3 className="inpLbl" htmlFor="cooling_off_period"><b>Cooling off period</b></h3><br/>
              <input type="date" className='dateInp autoInput' onChange={handleChange} value={inputFormatDate(input.cooling_off_period)}  placeholder="Enter cooling off period" name="cooling_off_period" id="cooling_off_period"  />
              
              
              <hr />
              {/* <p>By creating an account you agree to our <a href="#">Terms & Privacy</a>.</p> */}

              <button type="submit" className="registerbtn">Submit</button>
            </div>
          
          
        </form>
    </>
  )
}

export default AdminForm;

/*
{
    "co_pay": "asd",
    "claim_number": "asd",
    "form_type": "asd",
    "patient_name": "asd",
    "payee": "asd",
    "dos": "asd",
    "total_charge": "asd",
    "discount": "asd",
    "savings": "asd",
    "allowable": "asd",
    "paid": "asd",
    "nex_fees": "asd",
    "other_fees": "asd",
    "net_savings": "asd",
    "percentage_savings": "asd",
    "ck_number": "asd",
    "agrmt_sent": "asd",
    "ck_mailed": "asd",
    "ck_received": "asd",
    "ck_cleared": "asd",
    "reissued": "asd",
    "first_appeal_received": "asd",
    "date_to_send_closure_ltr": "asd",
    "closure_sent": "asd",
    "date_to_send_first_appeal_response": "asd",
    "first_appeal_response_sent": "asd",
    "second_appeal_received": "asd",
    "open_negotiation_notice_end_of_right_to_initiate_on": "asd",
    "open_negotiation_notice_received_date": "asd",
    "open_negotiation_period_ending": "asd",
    "notice_of_idr_initiation_end_of_right_to_initiate": "asd",
    "notice_of_idr_initiation_received_date": "asd",
    "selection_of_certified_idr_entity": "asd",
    "final_selection_of_idr_entity": "asd",
    "submission_of_offer": "asd",
    "selection_of_offer_and_decision": "asd",
    "payment_of_oon_rate": "asd",
    "cooling_off_period": "asd"
}

*/