import React from 'react'
import NavBar from '../../shared/navbar/navbar';

const PageNotFound = () => {
  return (
    <div>
        <NavBar />
        <div style={{textAlign:"center"}}>
            <h1>404 Not Found </h1>
        </div>
    </div>
    
  )
}

export default PageNotFound