import React, { useCallback, useEffect,useState } from 'react'

import {Route, Link, Routes, useParams,useNavigate} from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavBar from '../../shared/navbar/navbar';
import "./dashboard.css" ;

import axios from 'axios';
import { dateDifference, formatDate } from '../../shared/functions/service';
import { API_LINK } from '../../shared/functions/config';


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (
      event,
    ) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }



const Dashboard = () => {
    const navigate = useNavigate();
    const [forms, setForms] = useState([]);
    const [input, setInput] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const columns = ["Type", "Claim #","Patient name", "Payee", "DOS" ,"Created By","Created Date","Updated By","Updated Date","Action" ]
    // table codes start
    const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - forms.length) : 0;

    const handleChangePage = (
        event,
        newPage,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    // table codes end
    const handleSubmit = async(e) => {
        e.preventDefault();
        console.log("input",input)
        if(input && input.claim_number){
            try {
                
                // console.log(input);
                
                const claim_number = input.claim_number ;
                const token = localStorage.getItem("key") ;
                const response = await axios.get(`${API_LINK}/user-form/search?claim_number=${claim_number}`,{headers: {
                  'Authorization': `Bearer ${token}` 
                }})
                console.log("response",response)
                if(response.status == 200){
                    const result = response.data.result;
                    setForms(result)
                }else{
                    return alert("Form Error")
                }
            } catch (error) {
              console.log("error",error)
                // const msg = handleError(error);
                return alert(error.response.data.text)
            }  
        }else{
            alert("Please enter any claim number")
        }
        
    }

    const handleChange = (event)=>{
        
        setInput({...input,[event.target.name]:event.target.value})
    }

    const load = useCallback(async()=>{
        const token = localStorage.getItem("key") ;
            
        if(!token) return navigate("/")
       const response = await axios.get(API_LINK+"/user-form",{headers: {
            'Authorization': `Bearer ${token}` 
        }})
        // console.log(response)
        if(response.status == 200){
            const result = response.data.result;
            setForms(result)
        }
    },[])

    useEffect(() => {

        load()
    }, [load]);
    
    // console.log(forms)
  return (
    <>
    <NavBar/>
    <h1>Dashboard</h1>
    <hr />
    <div >
        <form className="example searchComponent"  style={{margin:"auto",maxWidth:"80%"}} onSubmit={handleSubmit}>
            <input type="text" onChange={handleChange} placeholder="claim # .." name="claim_number" /> &nbsp;&nbsp;
            <button type="submit">Search</button>
        </form>
        
    </div>
    <hr />
    
        <div style={{padding:"0px 20px "}}>
            {/* table view */}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column,index) => (
                            <TableCell
                                key={index}
                                align="center"
                                style={{ width: 160,color:"#ffffff",fontWeight:"600",fontSize:"16px" }}
                            >
                            {column}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? forms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : forms
                        ).map((row,index) => (
                            <TableRow key={index}>
                            <TableCell style={{ width: 160 }} align="center">
                                {row.form_type}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {row.claim_number}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {row.payee}
                            </TableCell>

                            <TableCell style={{ width: 160 }} align="center">
                                {formatDate(row.dos)}
                            </TableCell>

                            <TableCell style={{ width: 160 }} align="center">
                                {row.payee}
                            </TableCell>

                            <TableCell style={{ width: 160 }} align="center">
                                {row.created_by.name}
                            </TableCell>

                            <TableCell style={{ width: 160 }} align="center">
                                {formatDate(row.createdAt)}
                            </TableCell>

                            <TableCell style={{ width: 160 }} align="center">
                                {row.updated_by.name}
                            </TableCell>

                            <TableCell style={{ width: 160 }} align="center">
                                {formatDate(row.updatedAt)}
                            </TableCell>

                            <TableCell style={{ width: 160 }} align="center">
                                <Link className='action' to={"/adminForm/"+row._id} > Action </Link>
                            </TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                   
                </Table>
            </TableContainer>
            <div className='tableFooter'>
                
            <TablePagination
                        
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={3}
                        count={forms.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: {
                            'aria-label': 'rows per page',
                            },
                            native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
            </div>
            
        </div>
   
    </>
  )
};



export default Dashboard
/*

const formObject = {
  "co_pay": "",
  "claim_number": "",
  "form_type": "",
  "patient_name": "",
  "payee": "",
  "dos": "",
  "total_charge": "",
  "discount": "",
  "savings": "",
  "allowable": "",
  "paid": "",
  "nex_fees": "",
  "other_fees": "",
  "net_savings": "",
  "percentage_savings": "",
  "ck_number": "",
  "agrmt_sent": "",
  "ck_mailed": "",
  "ck_received": "",
  "ck_cleared": "",
  "reissued": "",
  "first_appeal_received": "",
  "date_to_send_closure_ltr": "",
  "closure_sent": "",
  "date_to_send_first_appeal_response": "",
  "first_appeal_response_sent": "",
  "second_appeal_received": "",
  "open_negotiation_notice_end_of_right_to_initiate_on": "",
  "open_negotiation_notice_received_date": "",
  "open_negotiation_period_ending": "",
  "notice_of_idr_initiation_end_of_right_to_initiate": "",
  "notice_of_idr_initiation_received_date": "",
  "selection_of_certified_idr_entity": "",
  "final_selection_of_idr_entity": "",
  "submission_of_offer": "",
  "selection_of_offer_and_decision": "",
  "payment_of_oon_rate": "",
  "cooling_off_period": ""
}*/

