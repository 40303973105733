import React,{useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import "./login.css";
import { API_LINK } from '../../shared/functions/config';

const Login = () => {
  const [input, setInput] = useState({email:"",password:""});
  const navigate = useNavigate();

    const handleSubmit = async(e) => {
        try {
            e.preventDefault();
            // console.log(input);

            const response = await axios.post(API_LINK+"/user/login",input)
            // console.log("response",response)
            if(response.status == 200){
                const result = response.data;
                localStorage.setItem("key",response.data.token)
                alert("Login successful");
                return navigate("/dashboard");
            }else{
                return alert("Wrong credentials")
            }
        } catch (error) {
          console.log("error",error)
            // const msg = handleError(error);
            if(error.response){
              return alert(error.response.data.text)
            }else{
              return alert("Server error")
            }
            
        }
    }
    const handleChange = (event)=>{
        
        setInput({...input,[event.target.name]:event.target.value})
    }

    useEffect(() => {
      
      
      return async() => {
        
        const token = localStorage.getItem("key");
        console.log(token)
        if(token){
          if(token.length > 10) {
            navigate("/dashboard");
            window.location.reload()
            
          } 
        }
        
      }
  }, []);


  return (
    <div>
        <h1>Login </h1>
        <form  autoComplete='off' onSubmit={handleSubmit}>
          <div className="container" >
              
              <p>Please fill in this form to create an account.</p>
              <hr />

              <label htmlFor="email"><b>Email</b></label>&nbsp;&nbsp;
              <input type="text" onChange={handleChange} placeholder="Enter Email" name="email" id="email" required />
              <br />
              <label htmlFor="psw"><b>Password</b></label>&nbsp;&nbsp;
              <input type="password" onChange={handleChange} placeholder="Enter Password" name="password" id="psw" required />

              
              <hr />
              {/* <p>By creating an account you agree to our <a href="#">Terms & Privacy</a>.</p> */}

              <button type="submit" className="registerbtn">Login</button>
            </div>
          
          
        </form>


    </div>
    
    
  )
}

export default Login