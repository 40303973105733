import React ,{useState,useCallback, useEffect}from 'react'
import NavBar from '../../shared/navbar/navbar';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import "./form.css";
import { incrementDate, getNexFeesValue, getPercentageSavingsValue, getNetSavingsValue, getChequeCleared, getAllowableValue} from '../../shared/functions/service';
import { API_LINK } from '../../shared/functions/config';
const formObject = {
  "co_pay": "",
  "claim_number": "",
  "form_type": "",
  "patient_name": "",
  "payee": "",
  "dos": "",
  "total_charge":"",
  "discount":"",
  "savings":"",
  "allowable":"",
  "paid":"",
  "nex_fees":"",
  "other_fees":"",
  "net_savings":"",
  "percentage_savings":"",
  "ck_number": "",
  "agrmt_sent": "",
  "ck_mailed": "",
  "ck_received": "",
  "ck_cleared": "",
  "reissued": "",
  "first_appeal_received": "",
  "date_to_send_closure_ltr": "",
  "closure_sent": "",
  "date_to_send_first_appeal_response": "",
  "first_appeal_response_sent": "",
  "second_appeal_received": "",
  "open_negotiation_notice_end_of_right_to_initiate_on": "",
  "open_negotiation_notice_received_date": "",
  "open_negotiation_period_ending": "",
  "notice_of_idr_initiation_end_of_right_to_initiate": "",
  "notice_of_idr_initiation_received_date": "",
  "selection_of_certified_idr_entity": "",
  "final_selection_of_idr_entity": "",
  "submission_of_offer": "",
  "selection_of_offer_and_decision": "",
  "payment_of_oon_rate": "",
  "cooling_off_period": ""
}

const NewForm = () => {

  const [formInput, setInput] = useState(formObject);
  const [isInputChange, setIsInputChange] = useState(false);
  const navigate = useNavigate();

    const handleSubmit = async(e) => {
        try {
            e.preventDefault();
            // console.log(formInput);
            
             // axios call
            const token = localStorage.getItem("key") ;
            const response = await axios.post(API_LINK+"/user-form",formInput,{headers: {
              'Authorization': `Bearer ${token}` 
            }})
            // console.log("response",response)
            if(response.status == 200){
                const result = response.data;
                
                alert("Submit successful");
                return navigate("/dashboard")
            }else{
                return alert("Form Error")
            }
            // axios call
        } catch (error) {
          console.log("error",error)
            // const msg = handleError(error);
            return alert(error.response.data.text)
        }
    }

    

    const nexFeesCalculate =() =>{
      
      
      const _result = getNexFeesValue(formInput)
      updateFormInput(_result)
      setIsInputChange(!isInputChange)
      // return _result
    }

    const netSavingsCalculate =() =>{
      
      const _result = getNetSavingsValue(formInput) ;
      
      updateFormInput(_result);
      // console.log("netSavingsCalculate",_result,formInput.net_savings)
      
      // return _result

    }

    const percentageSavingsCalculate =() =>{
      const _result = getPercentageSavingsValue(formInput) ;
      updateFormInput(_result)
      // return _result

    }

    const handleChange = useCallback((event)=>{
      if(event){
        
        const value  = {[event.target.name]:event.target.value};
        // console.log("value",value)
        setInput((formInput) => ({...formInput,...value  }));
        
      }
      setIsInputChange(!isInputChange)
    },[formInput])


    const updateFormInput = (value)=>{
      setInput((formInput) => ({...formInput,...value  }));
      
    }

    const allowableCalculate =() =>{
      
      const _result = getAllowableValue(formInput)
      updateFormInput(_result)
      setIsInputChange(!isInputChange)
      
    }

    const chequeCleared = ()=>{
      if(formInput.ck_cleared){
        const _result = getChequeCleared(formInput)
        updateFormInput(_result)
      }
      
    }

    useEffect(()=>{
      
      netSavingsCalculate();
      percentageSavingsCalculate();
      
    },[isInputChange])
    
  return (
    <>
      <NavBar/>
      <h1>Add New Record</h1>
      <p>Please fill in this form to generate new Ticket.</p>
      <form  autoComplete='off' onSubmit={handleSubmit}>
          <div className="formContainer" >
              
              
              <hr />

              <h3 className="inpLbl" htmlFor="type"><b>Type</b></h3><br/>
              <input type="text" onChange={handleChange} placeholder="Enter type" name="form_type" id="type" required />

              <br />
              <h3 className="inpLbl" htmlFor="claimNo"><b>Claim Number</b></h3><br/>
              <input type="text" onChange={handleChange} placeholder="Enter claim number" name="claim_number" id="claimNo" required />

              <br />
              <h3 className="inpLbl" htmlFor="patient_name"><b>Patient Name</b></h3><br/>
              <input type="text" onChange={handleChange} placeholder="Enter Patient Name" name="patient_name" id="patient_name" required />
              <br />
              <h3 className="inpLbl" htmlFor="payee"><b>Payee</b></h3><br/>
              <input type="text" onChange={handleChange} placeholder="Enter payee" name="payee" id="payee"  />
              <br />
              <h3 className="inpLbl" htmlFor="dos"><b>DOS</b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} placeholder="Enter DOS" name="dos" id="dos"  />
              <br />
              <h3 className="inpLbl" htmlFor="total_charge"><b>Total Charge</b></h3><br/>
              <span className='preInp'>$</span><input type="text" onChange={(e) => handleChange(e)} onBlur={()=>{allowableCalculate();percentageSavingsCalculate()}} placeholder="Enter total charge"  name="total_charge" id="total_charge"  />
              <br />
              <h3 className="inpLbl" htmlFor="discount"><b>Discount</b></h3><br/>
              <span className='preInp'>$</span><input type="text" onChange={handleChange} onBlur={()=>{allowableCalculate();netSavingsCalculate();nexFeesCalculate()}} placeholder="Enter discount" name="discount" id="discount"  />
              <br />
              <h3 className="inpLbl" htmlFor="savings"><b>Savings</b></h3><br/>
              <span className='preInp'>$</span><input type="text" onChange={handleChange} onBlur={()=>{allowableCalculate();netSavingsCalculate();nexFeesCalculate()}} placeholder="Enter savings" name="savings" id="savings"  />
              <br />
              <h3 className="inpLbl" htmlFor="allowable"><b>Allowable</b></h3><br/>
              <span className='preInp'>$</span><input type="text" className='autoInput' onChange={handleChange} placeholder="Enter Allowable" value={formInput.allowable} name="allowable" id="allowable"  />
              <br />
              <h3 className="inpLbl" htmlFor="co_pay"><b>Co Pay</b></h3><br/>
              <span className='preInp'>$</span><input type="text" onChange={handleChange} placeholder="Enter co pay" name="co_pay" id="co_pay"  />
              <br />
              <h3 className="inpLbl" htmlFor="paid"><b>Paid</b></h3><br/>
              <span className='preInp'>$</span><input type="text" onChange={handleChange} placeholder="Enter paid" name="paid" id="paid"  />
              <br />
              <h3 className="inpLbl" htmlFor="nex_fees"><b>Nex fees</b></h3><br/>
              <span className='preInp'>$</span><input type="text" className='autoInput' onChange={handleChange} onBlur={()=>{netSavingsCalculate()} } placeholder="Enter Nex Fees" value={formInput.nex_fees} name="nex_fees" id="nex_fees"  />
              <br />
              <h3 className="inpLbl" htmlFor="other_fees"><b>Other Fees</b></h3><br/>
              <span className='preInp'>$</span><input type="text" onChange={handleChange} onBlur={()=>{netSavingsCalculate();percentageSavingsCalculate()} } placeholder="Enter other fees" name="other_fees" id="other_fees"  />
              <br />
              <h3 className="inpLbl" htmlFor="net_savings"><b>Net Savings</b></h3><br/>
              <span className='preInp'>$</span><input type="text" className='autoInput' onChange={()=>{handleChange();}} placeholder="Enter net savings" value={formInput.net_savings} onBlur={()=>{percentageSavingsCalculate()} } name="net_savings" id="net_savings"  />
              <br />
              <h3 className="inpLbl" htmlFor="percentage_savings"><b>Percentage savings</b></h3><br/>
              <span className='preInp'>%</span><input type="text" className='autoInput' onChange={handleChange} placeholder="Enter percentage savings" value={formInput.percentage_savings} name="percentage_savings" id="percentage_savings"  />
              <br />
              <h3 className="inpLbl" htmlFor="ck_number"><b>CK Number</b></h3><br/>
              <input type="text" onChange={handleChange} placeholder="Enter ck number" name="ck_number" id="ck_number"  />


              <br />
              <h3 className="inpLbl" htmlFor="agrmt_sent"><b>Agrmt Sent</b></h3><br/>
              <input type="text" onChange={handleChange} placeholder="Enter Agrmt Sent" name="agrmt_sent" id="agrmt_sent"  />
              <br />
              <h3 className="inpLbl" htmlFor="ck_mailed"><b>CK Mailed</b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} placeholder="Enter ck mailed" name="ck_mailed" id="ck_mailed"  />
              <br />
              <h3 className="inpLbl" htmlFor="ck_received"><b>CK Received</b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} placeholder="Enter ck received" name="ck_received" id="ck_received"  />
              <br />
              <h3 className="inpLbl" htmlFor="ck_cleared"><b>CK Cleared</b></h3><br/>
              <input type="date" className='dateInp' onChange={(e)=>{handleChange(e);chequeCleared();}} onBlur={()=>{chequeCleared();}} placeholder="Enter ck cleared" name="ck_cleared" id="ck_cleared"  />
              <br />
              <h3 className="inpLbl" htmlFor="reissued"><b>Reissued</b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} placeholder="Enter reissued" name="reissued" id="reissued"  />
              <br />
              {
                
                formInput.ck_received ? (
                  <div>
                <hr />
                <h1>Appeal Tracker</h1>
                <h3 className="inpLbl" htmlFor="first_appeal_received"><b>First appeal received</b></h3><br/>
                <input type="date" onChange={handleChange} placeholder="Enter first appeal received" name="first_appeal_received" id="first_appeal_received"  />
                <br />
                <h3 className="inpLbl" htmlFor="date_to_send_closure_ltr"><b> Date to send closure ltr</b></h3><br/>
                <input type="date" onChange={handleChange} placeholder="Enter date to send closure ltr" name="date_to_send_closure_ltr" id="date_to_send_closure_ltr"  />
                <br />
                <h3 className="inpLbl" htmlFor="closure_sent"><b>Closure Sent</b></h3><br/>
                <input type="date" onChange={handleChange} placeholder="Enter closure sent" name="closure_sent" id="closure_sent"  />
                <br />
                <h3 className="inpLbl" htmlFor="date_to_send_first_appeal_response"><b> Date to send first appeal response</b></h3><br/>
                <input type="date" onChange={handleChange} placeholder="Enter date to send first appeal response" name="date_to_send_first_appeal_response" id="date_to_send_first_appeal_response"  />
                <br />
                <h3 className="inpLbl" htmlFor="first_appeal_response_sent"><b>First appeal response Sent</b></h3><br/>
                <input type="date" onChange={handleChange} placeholder="Enter first appeal response Sent" name="first_appeal_response_sent" id="first_appeal_response_sent"  />
                <br />
                <h3 className="inpLbl" htmlFor="second_appeal_received"><b>CK Second appeal received</b></h3><br/>
                <input type="date" onChange={handleChange} placeholder="Enter Second appeal received" name="second_appeal_received" id="second_appeal_received"  />
                <br />
                <hr /></div>):null
              }
              
              {/* <h1>IDR Tracker</h1>
              <h3 className="inpLbl" htmlFor="open_negotiation_notice_end_of_right_to_initiate_on"><b>Open negotiation notice end of right to initiate on</b></h3><br/>
              <input type="text" onChange={handleChange} placeholder="Enter open_negotiation_notice_end_of_right_to_initiate_on" name="open_negotiation_notice_end_of_right_to_initiate_on" id="open_negotiation_notice_end_of_right_to_initiate_on"  />
              <br />
              <h3 className="inpLbl" htmlFor="open_negotiation_notice_received_date"><b>Open negotiation notice received date </b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} placeholder="Enter open_negotiation_notice_received_date" name="open_negotiation_notice_received_date" id="open_negotiation_notice_received_date"  />
              <br />
              <h3 className="inpLbl" htmlFor="open_negotiation_period_ending"><b>open_negotiation_period_ending</b></h3><br/>
              <input type="text" onChange={handleChange} placeholder="Enter open_negotiation_period_ending" name="open_negotiation_period_ending" id="open_negotiation_period_ending"  />
              <br />
              <h3 className="inpLbl" htmlFor="notice_of_idr_initiation_end_of_right_to_initiate"><b>Open negotiation period ending</b></h3><br/>
              <input type="text" onChange={handleChange} placeholder="Enter notice_of_idr_initiation_end_of_right_to_initiate" name="notice_of_idr_initiation_end_of_right_to_initiate" id="notice_of_idr_initiation_end_of_right_to_initiate"  />
              <br />
              <h3 className="inpLbl" htmlFor="notice_of_idr_initiation_received_date"><b>Notice of idr initiation received date</b></h3><br/>
              <input type="date" className='dateInp' onChange={handleChange} placeholder="Enter notice_of_idr_initiation_received_date" name="notice_of_idr_initiation_received_date" id="notice_of_idr_initiation_received_date"  />
              <br />
              <h3 className="inpLbl" htmlFor="selection_of_certified_idr_entity"><b>Selection of certified idr entity</b></h3><br/>
              <input type="text" onChange={handleChange} placeholder="Enter selection_of_certified_idr_entity" name="selection_of_certified_idr_entity" id="selection_of_certified_idr_entity"  />
              <br />
              <h3 className="inpLbl" htmlFor="final_selection_of_idr_entity"><b>Final selection of idr entity</b></h3><br/>
              <input type="text" onChange={handleChange} placeholder="Enter final_selection_of_idr_entity" name="final_selection_of_idr_entity" id="final_selection_of_idr_entity"  />
              <br />
              <h3 className="inpLbl" htmlFor="submission_of_offer"><b>Submission of offer</b></h3><br/>
              <input type="text" onChange={handleChange} placeholder="Enter submission_of_offer" name="submission_of_offer" id="submission_of_offer"  />
              <br />
              <h3 className="inpLbl" htmlFor="selection_of_offer_and_decision"><b>Selection of offer and decision</b></h3><br/>
              <input type="text" onChange={handleChange} placeholder="Enter selection_of_offer_and_decision" name="selection_of_offer_and_decision" id="selection_of_offer_and_decision"  />
              <br />
              <h3 className="inpLbl" htmlFor="payment_of_oon_rate"><b>Payment of oon rate</b></h3><br/>
              <input type="text" onChange={handleChange} placeholder="Enter Payment of oon rate" name="payment_of_oon_rate" id="payment_of_oon_rate"  />
              <br />
              <h3 className="inpLbl" htmlFor="cooling_off_period"><b>Cooling off period</b></h3><br/>
              <input type="text" onChange={handleChange} placeholder="Enter cooling off period" name="cooling_off_period" id="cooling_off_period"  />
              
              
              <hr /> */}
              {/* <p>By creating an account you agree to our <a href="#">Terms & Privacy</a>.</p> */}

              <button type="submit" className="registerbtn">Submit</button>
            </div>
          
          
        </form>
    </>
  )
}

export default NewForm;

/*
{
    "co_pay": "asd",
    "claim_number": "asd",
    "form_type": "asd",
    "patient_name": "asd",
    "payee": "asd",
    "dos": "asd",
    "total_charge": "asd",
    "discount": "asd",
    "savings": "asd",
    "allowable": "asd",
    "paid": "asd",
    "nex_fees": "asd",
    "other_fees": "asd",
    "net_savings": "asd",
    "percentage_savings": "asd",
    "ck_number": "asd",
    "agrmt_sent": "asd",
    "ck_mailed": "asd",
    "ck_received": "asd",
    "ck_cleared": "asd",
    "reissued": "asd",
    "first_appeal_received": "asd",
    "date_to_send_closure_ltr": "asd",
    "closure_sent": "asd",
    "date_to_send_first_appeal_response": "asd",
    "first_appeal_response_sent": "asd",
    "second_appeal_received": "asd",
    "open_negotiation_notice_end_of_right_to_initiate_on": "asd",
    "open_negotiation_notice_received_date": "asd",
    "open_negotiation_period_ending": "asd",
    "notice_of_idr_initiation_end_of_right_to_initiate": "asd",
    "notice_of_idr_initiation_received_date": "asd",
    "selection_of_certified_idr_entity": "asd",
    "final_selection_of_idr_entity": "asd",
    "submission_of_offer": "asd",
    "selection_of_offer_and_decision": "asd",
    "payment_of_oon_rate": "asd",
    "cooling_off_period": "asd"
}

*/