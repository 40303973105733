
import './App.css';
import { Routes,Route } from 'react-router-dom';

import PageNotFound from "./components/pages/404/notFound";
import Login from "./components/pages/auth/login"
import Dashboard from './components/pages/dashboard/dashboard';
import NewForm from './components/pages/form/newForm';
import AdminForm from './components/pages/form/adminForm';



function App() {
  return (
    <>
      <div className='App'>
      <Routes>
        <Route path="/adminForm/:id" exact element={<AdminForm />} />
        <Route path="/userForm" exact element={<NewForm />} />
        <Route path="/dashboard" exact element={<Dashboard />} />
        <Route path="/" exact element={<Login />} />
        <Route path="*" exact element={<PageNotFound />} />         
      </Routes>
      </div>
  </>
  );
}

export default App;
